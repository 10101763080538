import React from 'react'
import * as styles from './BlogList.module.css'
import BlogTile from '../../tile/BlogTiile/BlogTile'
import { useStaticQuery, graphql } from 'gatsby';

export default function BlogList() {
    const data = useStaticQuery(graphql`
    query blogs {
        allMdx(filter: {frontmatter: {title: {ne: ""}, published: {eq: true}}}) {
          nodes {
            frontmatter {
              title
              image {
                absolutePath
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              date(formatString: "DD MMM YYYY")
              published
            }
            body
            timeToRead
            slug
          }
        }
      }
    `);
    const blogs = data.allMdx.nodes;
    console.log(data.allMdx.nodes);
    return (
        <div className={styles.blogList}>
            {   
                blogs.map((data)=>{
                    return <BlogTile
                        title={data.frontmatter.title}
                        date={data.frontmatter.date}
                        imageData={data.frontmatter.image.childImageSharp}
                        key={data.slug}
                        slug={data.slug}
                    />
                })
            }
        </div>
    )
}
