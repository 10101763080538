import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as styles from './BlogTile.module.css'


export default function BlogTile({title, date, slug, imageData}) {

    const image = getImage(imageData)

    return (
        <div className={styles.tile}>
            <Link to={slug.toLowerCase()}>
                <GatsbyImage image={image} alt="" className={styles.img} imgClassName={styles.img}/>
            </Link>
            <h2>{title}</h2>
            <p>{date}</p>
            <Link to={slug.toLowerCase()} className={styles.view}>
                VIEW
            </Link>
        </div>
    )
}
